$time: 0.75s;
$ease: ease-in-out;

.App {
    // color, background, border-color, z-index
    --default-transition: all #{$time} #{$ease};
    --sidebar-transition: var(--default-transition), margin #{$time} cubic-bezier(0.50, -0.6, 0.50, 1.6);
    --loading-transition: opacity #{$time} #{$ease};
    @media (prefers-reduced-motion) {        
        --sidebar-transition: --default-transition, margin none;
        --loading-transition: none;
    }
}


@keyframes ring-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }