@import "general/devices";

.topbar {
    position: sticky;
    top:0;
    background: var(--top-menu-background);
    height: var(--top-bar-size);
    padding-left: 5rem;
    z-index: 45;
    transition: var(--default-transition);

    box-shadow: var(--menu-shadow);

    @media screen and ($big_device) {
        padding-left: var(--content-buffer);
    }

    button {
        position: relative;
        height: var(--top-bar-size);
        width: var(--top-bar-size);
        z-index: 50;

        background: transparent;

        border: none;
        border-color: var(--top-menu-text-color-faded);
        border-left-style: solid;
        border-right-style: solid;
        border-width: .15rem;
        &:not(:first-child){
            margin-left: -.15rem;
        }

        transition: var(--default-transition);
        >* {
            // icon
            font-size: xx-large;
            color: var(--top-menu-text-color-faded);

            transition: var(--default-transition);
        }

        &:hover {
            z-index: 90;
            border-color: var(--top-menu-text-color);

            transition: var(--default-transition);

            >* {
                // icon
                color: var(--top-menu-text-color);
                transition: var(--default-transition);
            }
        }
    }
}