@import "general/devices";

.sidebar {
    position: fixed;

    width: var(--side-bar-size);
    height: 100vh;
    background: var(--side-menu-background);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--side-menu-text-color);

    text-align: center;
    box-shadow: var(--menu-shadow);

    z-index: 99;
    flex-shrink: 0;

    @media screen and ($small_device) {
        width: 93vw;

        &.minimized {
            transform: translateX(calc(93% * -0.95));
        }
    }

    transition: var(--sidebar-transition);

    img.circle {
        transition: var(--sidebar-transition);

        margin-top: 7rem;

        .App.light & {
            margin-top: 1rem;
        }

        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        border: .15rem solid var(--accent-color);
    }

    h2 {
        font-size: 2rem;
        margin-bottom: 0;
    }

    sub {
        display: block;
        font-size: 1rem;
        margin-top: -0.33rem;
        margin-bottom: 1rem;
        color: var(--accent-color);
        transition: var(--default-transition);

        .App.light & {
            color: var(--side-menu-text-color-faded);
        }
    }

    nav {
        display: flex;
        flex-direction: column;

        a {
            font-size: x-large;
            padding: .1rem 0;

            text-decoration: none;

            color: var(--side-menu-text-color);

            @media screen and ($big_device) {
                color: var(--side-menu-text-color-faded);
            }

            &:hover {
                color: var(--side-menu-text-color);
            }

            transition: var(--default-transition);
        }
    }

    .expand {
        position: absolute;
        top: 0;
        left: 88%;
        width: 12%;
        height: 100%;

        display: flex;
        align-items: center;
        font-size: 2em;

        @media screen and ($big_device) {
            display: none;
        }

        .chevrons {

            display: flex;
            transition: var(--default-transition);

            height: 1em;
            width: 1.2em;

            &.rotate {
                // -x -> counter-clockwise
                transform: rotate(-180deg);
            }

            svg {
                position: absolute;

                &:nth-child(1) {
                    left: 20%
                }
            }
        }
    }
}