@import "general/devices";

.content {
    flex-grow: 1;
    padding: 0 1.5rem 2rem 5rem;
    background-color: var(--content-background);
    color: var(--content-text-color);
    transition: var(--default-transition);

    @media screen and ($big_device) {
        padding-left: var(--content-buffer);
    }

    hr {
        border-style: solid;
        border-color: var(--accent-color);
        transition: var(--default-transition);
    }
}

.cv {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 2rem;

    @media screen and ($big_device) {
        flex-direction: row;
    }

    .mainSection {
        flex-grow: 1;

        .introduction {
            .name {
                font-size: 2.5rem;
                margin-bottom: .5rem;
            }

            .subtitle {
                font-size: 1.5rem;
                margin-top: 0;
                margin-bottom: .5rem;
            }

            p {
                b {
                    color: var(--accent-color);
                }
            }

            .stats {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                row-gap: 0.5em;

                @media screen and ($big_device) {
                    flex-direction: row;
                }

                .stat {
                    flex-basis: 50%;

                    @media screen and ($big_device) {
                        &:nth-child(even) {
                            text-align: right;
                        }

                        &:last-child {
                            margin-top: -4em;
                        }
                    }

                    svg {
                        color: var(--accent-color);
                        vertical-align: top;
                    }

                    .text {
                        display: inline-block;
                        text-align: left;
                    }
                }
            }
        }

        .experience{
            h2 {
                margin-top: 0;
            }
        }

        .experience,
        .education {
            .item {
                .time {
                    color: var(--content-soft-text-color);
                    font-size: 0.9em;
                }

                h3 {
                    margin-top: 0;
                    margin-bottom: 0.3em;
                    font-weight: normal;

                    .soft {
                        color: var(--content-soft-text-color);
                    }                    
                }

                ul {
                    margin-top: 0;
                    color: var(--content-soft-text-color);
                }
            }
        }
    }

    .sideSection {
        flex-shrink: 0;
        width: 100%;

        @media screen and ($big_device) {
            max-width: 25%;
            position: sticky;
            top: var(--top-bar-size);
            margin-top: 6rem;
        }

        .skills {
            h2 {
                font-size: 1.2em;
                margin-bottom: 0;
            }

            .skill-list {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .skill {
                    // kind of "bubble-like" effect
                    border-radius: 1rem;
                    border: 1px solid var(--accent-color);
                    padding: 0.2em 0.5em;
                    margin: 0.2em;
                }
            }
        }
    }
}