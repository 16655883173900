#loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--loading-background);
  z-index: 99999;
  opacity: 0;
  transition: var(--loading-transition);
  transition-delay: 1s;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: default;
  }
  .spinner {
    margin:auto;
    padding-top: calc(50vh - 10em);
    display: block;
    width: 10em;
    height: 10em;
    &:after {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      margin: .5em;
      border: 6px solid #fff;
      border-radius: 50%;
      border-top-color: transparent;
      border-right-color: var(--loading-color);
      border-bottom-color: transparent;
      border-left-color: var(--loading-color);
      
      animation: ring-loader .8s linear infinite;
    }
  }
}