@import "general/general";

.App {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  position: relative;  

  transition: var(--default-transition);
  .content-wrapper{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 1;
  }
}

