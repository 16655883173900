
$light: #fafafa;
$dark: #0b1616;

// cyan-ish
$accent: #00aba5;

$fade-slight: 20%;

$fade-medium: 40%;

$fade-strong: 40%;

.App{    
    --shadow-color: rgba(0,0,0,1);

    --light-color: #{$light};
    --dark-color: #{$dark};
    --accent-color: #{$accent};
    &.dark{
        --loading-background: #{$dark};
        --loading-color: #{$light};

        --side-menu-background: #{$dark} url("../../assets/new-york-night-no-moon.webp");
        --side-menu-text-color-faded: #{darken($light,$fade-medium)};
        --side-menu-text-color: #{$light};

        --top-menu-background: #{$dark};
        --top-menu-text-color-faded: #{darken($light,$fade-strong)};
        --top-menu-text-color: #{$light};

        --content-background: #{$dark};
        --content-text-color: #{$light};

        --content-soft-text-color: #{darken($light,$fade-medium)};
    }
    &.light{
        --loading-background: #{$light};
        --loading-color: #{$dark};

        --side-menu-background: #{$light} url("../../assets/new-york-day.webp");
        --side-menu-text-color: #{$dark};
        --side-menu-text-color-faded: #{lighten($dark,$fade-slight)};

        --top-menu-background: #{$light};
        --top-menu-text-color-faded: #{lighten($dark,$fade-strong)};
        --top-menu-text-color: #{$dark};

        --content-background: #{$light};
        --content-text-color: #{$dark};

        --content-soft-text-color: #{lighten($dark,$fade-slight)};
    }
}