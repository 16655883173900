a.external {
    color: var(--content-text-color);
    text-decoration: none;
    border-bottom: 1px solid var(--accent-color);
    transition: var(--default-transition);

    svg {
        vertical-align: -15%;
        color: var(--accent-color);
        transition: var(--default-transition);
    }

    &:hover {
        border-color: var(--content-text-color);

        svg {
            color: var(--content-text-color);
        }
    }
}